import { useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import NotificationModel from "../Models/NotificationsModel";
import { useAppContext } from "../Contexts/AppContext";

const useFetchNotifications = (isLoggedIn) => {
    const { setNotificationsCount, setNotifications } = useAppContext();

    useEffect(() => {
        if (!isLoggedIn) return;

        const fetchNotifications = async () => {
            try {
                const response = await axiosInstance.get("notifications/get_notifications");
                const notifications = response.data.map((notification) => {
                    const dateTime = new Date(notification.dateTime);
                    const timeDifference = Date.now() - dateTime.getTime();
                    const minutes = Math.floor(timeDifference / (1000 * 60));
                    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    let timeDisplay;

                    if (minutes < 60) {
                        timeDisplay = `Há ${minutes} minutos atrás`;
                    } else if (hours < 24) {
                        timeDisplay = `Há ${hours} horas atrás`;
                    } else {
                        timeDisplay = `Há ${days} dias atrás`;
                    }

                    return new NotificationModel(
                        notification.notificationTitle,
                        notification.notificationMessage,
                        notification.senderName,
                        timeDisplay,
                        notification.url
                    );
                });

                setNotifications(notifications);
                setNotificationsCount(notifications.length);
            } catch (e) {
                console.error("Failed to get notifications: ", e);
            }
        };

        fetchNotifications();
    }, [isLoggedIn, setNotifications, setNotificationsCount]);
};

export default useFetchNotifications;