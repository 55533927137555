import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import VerifyInput from "../../utils/VerifyInput";
import {ConvertToReadableDateInverted} from "../../utils/ConvertToReadableDate";
import {
    Alert,
    Box, Button,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel, Input,
    Modal,
    ModalDialog,
    Sheet,
    Typography
} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

export default function QRCodeDialog({ open, setOpen, material }) {
    const handleClose = () => {
        setOpen(false);
    }


    useEffect(() => {

    }, []);

    return (
        <Sheet>
            <Modal
                open={open}
                onClose={() => handleClose()}
                keepMounted={false}
            >
                <ModalDialog sx={{
                    minWidth: "580px",
                    maxWidth: "580px"
                }}>
                    <DialogTitle>
                        Código QR
                    </DialogTitle>
                    <DialogContent>
                        <img src={`data:image/svg+xml;base64,${material.qrCodeBase64String}`}/>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </Sheet>
    )
}