import BeableBase from "../BeableBase";
import Header from "../../components/Skus/Header";
import {Box, Button, CircularProgress, Link, Sheet, Table, Typography} from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {useNavigate, useSearchParams} from "react-router-dom";
import MaintenancePlanTable from "../../components/Maintenance/MaintenancePlanTable";
import axiosInstance from "../../utils/axiosInstance";
import {useEffect, useState} from "react";
import CreateMaintenanceOrderDialog from "../../components/Maintenance/CreateMaintenanceOrderDialog";

export default function MaintenancePlanPage({setIsLoggedIn}) {
    const navigate = useNavigate();
    const [canLoadPage, setCanLoadPage] = useState(false);
    const [searchParams] = useSearchParams();
    const [plan, setPlan] = useState({});
    const [open, setOpen] = useState(false);
    const [equipmentToEdit, setEquipmentToEdit] = useState(null);

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="/equipamentos" color="neutral">
            <Typography>Equipamentos</Typography>
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>{plan.equipmentName}</Typography>
        </Link>,
    ];

    const handleAddCreateOrder = () => {
        setOpen(true)
    }

    const fetchPlan = async () => {
        try {
            const code = searchParams.get("code")

            const response = await axiosInstance.get(`equipments/get_plan?code=${code}`);

            setPlan(response.data)

            console.log(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchPlan();

            setCanLoadPage(true);
        }
        fetchData()
    }, []);

    return (
        <BeableBase indexSelected={20} setIsLoggedIn={setIsLoggedIn}>
            <Header linkItems={path} />
            <Box
                sx={{
                    width: "100%",
                    mt: 4,
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Typography level="h2">
                    {plan.equipmentName}
                </Typography>
                <Button
                    onClick={() => {
                        handleAddCreateOrder()
                    }}
                    size="sm"
                    sx={{
                        maxHeight: "32px",
                        minHeight: "32px",
                    }}
                >
                    Adicionar ordem de manutenção
                </Button>
            </Box>
            { canLoadPage && (
                    <>
                        <MaintenancePlanTable orders={plan.maintenanceOrders}/>
                        <CreateMaintenanceOrderDialog open={open} setOpen={setOpen} fetchOrders={fetchPlan} type={"plan"} equipment={plan.equipmentId} line={plan.lineId}/>
                    </>
                )
            }

        </BeableBase>
    )
}