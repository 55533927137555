import BeableBase from "./BeableBase";
import {Box, Link, Typography} from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Header from "../components/Skus/Header";
import ProductionLineCard from "../components/LinhaEnchimento/ProductionLineCard";
import GlassBottleIcon from "../imgs/Glass_Bottle.png"
import ShuffleIcon from "../imgs/shuffle-icon-warning.png"
import axiosInstance from "../utils/axiosInstance";
import {useEffect, useState} from "react";
import {ConvertToReadableDateNormal} from "../utils/ConvertToReadableDate";

export default function ProductionLinesPage({ setIsLoggedIn }) {
    const [productionLines, setProductionLines] = useState([])
    const [canLoad, setCanLoad] = useState(false)

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>Linhas Produção</Typography>
        </Link>,
    ];

    const fetchProductionLines = async () => {
        try {
            const response = await axiosInstance.get("linhas/get_linhas")

            console.log(response.data)


            console.log(ConvertToReadableDateNormal(response.data.nextMaintenance))
            setProductionLines(response.data)
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        const getInfo= async () => {
            await fetchProductionLines();
            setCanLoad(true);
        }

        getInfo();
    }, []);

    if (!canLoad) {
        return;
    }

    return (
        <BeableBase indexSelected={19} setIsLoggedIn={setIsLoggedIn}>
            <Header linkItems={path} />
            <Box
                sx={{
                    width: "100%",
                    mt: 4,
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 4,
                }}
            >
                <Typography level="h2">
                    Linhas Produção
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                gap: 2
            }}>
                { productionLines.map((productionLine, index) => (
                    <ProductionLineCard
                        key={index}
                        icon={GlassBottleIcon}
                        line={productionLine}
                        color={"primary"}
                    />
                )
                )}
                {/*<ProductionLineCard*/}
                {/*    icon={GlassBottleIcon}*/}
                {/*    title={"Linha Vidro"}*/}
                {/*    maintenanceOrdersLength={20}*/}
                {/*    equipmentsLength={10}*/}
                {/*    color={"primary"}*/}
                {/*/>*/}
                {/*<ProductionLineCard*/}
                {/*    icon={ShuffleIcon}*/}
                {/*    title={"Linha Mista"}*/}
                {/*    maintenanceOrdersLength={20}*/}
                {/*    equipmentsLength={10}*/}
                {/*    color={"warning"}*/}
                {/*/>*/}
            </Box>
        </BeableBase>
    )
}