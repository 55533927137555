import {Box, Button, DialogContent, DialogTitle, Modal, ModalDialog, Option, Sheet} from "@mui/joy";
import React, {useEffect, useState} from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DetailsInput from "../Materials/DetailsInput";
import axiosInstance from "../../utils/axiosInstance";
import DetailsSelect from "../DetailsSelect";

function ViewMaterialDetailsDialog({ open, setOpen, equipment, fetchEquipments }) {
    const [canEdit, setCanEdit] = useState(false);
    const [linesOptions, setLinesOptions] = useState([]);
    const [manufacturesOptions, setManufacturesOptions] = useState([]);
    const [canLoad, setCanLoad] = useState(false);
    const [body, setBody] = useState({name: "", id: null, lineId: null, lineName: "", model: "", year: null, serialNumber: null, manufacturerId: null});

    const handleClickEdit = () => {
        setCanEdit(true);
    }

    const fetchLines = async () => {
        // Fetch lines
        try {
            const response = await axiosInstance.get("linhas/get_linhas");

            setLinesOptions(() => {
                return response.data.map((line) => {
                    return {id: line.id, name: line.name}
                });
            });
            console.log(response.data)
        } catch (e) {
            console.error(e);
        }
    }

    const fetchManufacturers = async () => {
        try {
            const response = await axiosInstance.get("/manufacturers/get_manufacturers");

            console.log(response.data)

            setManufacturesOptions(() => {
                return response.data.map((manufacturer) => {
                    return {id: manufacturer.id, name: manufacturer.name}
                });
            });
        } catch (error) {
            console.error("Error fetching manufacturers:", error);
        }
    }

    const handleClose = () => {
        setCanEdit(false);
        setOpen(false);
    }

    const handleEditBody = (e, field, value) => {
            setBody((prev) => {
                return {...prev, [field]: value};
            });
    }

    const handleSave = async (e) => {
        e.preventDefault();

        const putBody = {
            EquipmentId: equipment.id,
            LineId: body.lineId,
            Model: body.model,
            Year: body.year,
            SerialNumber: body.serialNumber,
            ManufacturerId: body.manufacturerId
        }

        const response= await axiosInstance.put(`equipments/update`, putBody);

        if (response.status === 200) {
            setCanEdit(false);
            await fetchEquipments(1, 16);
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchLines();
            await fetchManufacturers()
        }

        fetchData();
        setCanLoad(true);
    }, []);

    useEffect(() => {
        setBody({
                lineId: equipment.productionLineId,
                lineName: equipment.productionLineName,
                model: equipment.model,
                year: equipment.year,
                serialNumber: equipment.serialNumber,
                manufacturerId: equipment.manufacturerId,
                manufacturerName: equipment.manufacturerName
            });
    }, [equipment]);

    useEffect(() => {
        console.log(body)
    }, [body]);

    if (canLoad === true) {
        return (
        <Sheet>
            <Modal
                open={open}
                onClose={() => handleClose()}
                keepMounted={false}
            >
                <ModalDialog sx={{
                    maxHeight: "80vh",
                    maxWidth: "60vw",
                    minWidth: "40vw",
                    pr: 0,
                    pl: 3.5
                }}>

                    <DialogTitle>
                        Detalhes do equipamento
                    </DialogTitle>
                    <DialogContent sx={{
                        scrollbarGutter: "stable",
                        pr: 1,
                    }}>
                        <form id={"update-form"} onSubmit={(e) => {handleSave(e)}}>
                            <Box sx={{display: "flex", flexDirection: "column", mt: 3, gap: 1.6}}>
                                <DetailsInput
                                    inputValue={equipment.code}
                                    canEdit={canEdit}
                                    text={"Código"}
                                    disabled={true}
                                />

                                <DetailsInput
                                    inputValue={equipment.name}
                                    canEdit={canEdit}
                                    text={"Nome do equipamento"}
                                    disabled={true}
                                />
                                <DetailsSelect
                                    onChange={(e, newValue) => handleEditBody(e, "lineId", newValue)}
                                    value={body.lineId}
                                    valueText={body.lineName}
                                    canEdit={canEdit}
                                    disabled={!canEdit}
                                    text={"Linha de produção"}
                                >
                                    {linesOptions.map((option) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </DetailsSelect>
                                <DetailsSelect
                                    onChange={(e, newValue) => handleEditBody(e, "manufacturerId", newValue)}
                                    value={body.manufacturerId}
                                    valueText={body.manufacturerName}
                                    canEdit={canEdit}
                                    disabled={!canEdit}
                                    text={"Fabricante"}
                                >
                                    {manufacturesOptions.map((option) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </DetailsSelect>
                                <DetailsInput
                                    inputValue={body.model}
                                    canEdit={canEdit}
                                    text={"Modelo"}
                                    disabled={!canEdit}
                                    onChange={(e) => {handleEditBody(e, "model", e.target.value)}}
                                />
                                <DetailsInput
                                    inputValue={body.year}
                                    canEdit={canEdit}
                                    text={"Ano"}
                                    disabled={!canEdit}
                                    onChange={(e) => {handleEditBody(e, "year", e.target.value)}}
                                />
                                <DetailsInput
                                    inputValue={body.serialNumber}
                                    canEdit={canEdit}
                                    text={"Número de série"}
                                    disabled={!canEdit}
                                    onChange={(e) => {handleEditBody(e, "serialNumber", e.target.value)}}
                                />
                            </Box>
                        </form>
                    </DialogContent>
                    <Box
                        sx={{

                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            pt: 3,
                            mr: 3.5
                        }}
                    >
                        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
                            <Button
                                variant={"soft"}
                                color={"warning"}
                                size={"sm"}
                                disabled={canEdit}
                                startDecorator={<ModeEditOutlinedIcon/>}
                                onClick={() => {handleClickEdit()}}
                            >
                                Editar
                            </Button>
                            <Button
                                form={"update-form"}
                                variant={"solid"}
                                color={"primary"}
                                size={"sm"}
                                disabled={!canEdit}
                                startDecorator={<SaveIcon/>}
                                type={"submit"}
                            >
                                Salvar
                            </Button>
                        </Box>
                        <Button
                            sx={{
                                ".MuiButton-startDecorator": {position: "relative", top: 1.2}
                            }}
                            variant={"solid"}
                            color={"danger"}
                            size={"sm"}
                            startDecorator={<CancelIcon/>}
                        >
                            Fechar
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </Sheet>
    );
        }
}

export default ViewMaterialDetailsDialog;