import {
    Button,
    Chip,
    Dropdown,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Sheet,
    Table,
    Typography
} from "@mui/joy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "../Skus/Pagination";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import React, {useState} from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useNavigate} from "react-router-dom";
import ViewEquipmentDetailsDialog from "./ViewEquipmentDetailsDialog";

function EquipmentsTable({equipments, fetchEquipments, currentPage, setCurrentPage, totalPages}) {
    const navigate = useNavigate();
    const [openDetails, setOpenDetails] = useState(false);
    const [equipmentDetails, setEquipmentDetails] = useState({});

    const handleViewDetails = (equipment) => {
        setEquipmentDetails(equipment);
        console.table(equipment);
        setOpenDetails(true);
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    mt: 3,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}
            >
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                    }}>
                    <thead>
                    <tr>
                        <th style={{width: "10%"}}>Código</th>
                        <th style={{width: "20%"}}>Nome</th>
                        <th style={{width: "15%"}}>Fabricante</th>
                        <th style={{width: "15%"}}>Linha de Produção</th>
                        <th style={{width: "10%"}}>Modelo</th>
                        <th style={{width: "10%"}}>Ano</th>
                        <th style={{width: "10%"}}>Número de série</th>
                        <th style={{width: "10%", textAlign: "right"}}>Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    {equipments.map((equipment, index) => (
                        <tr style={{height: "38px"}} key={index}>
                            <td>
                                <Button
                                    onClick={() => handleViewDetails(equipment)}
                                    color={"neutral"}
                                    variant={"plain"}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500, // You can use "bold", "normal", or a numeric value like 700
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            color: "rgb(52, 98, 172, 1)",
                                            textDecoration: "underline"
                                        },
                                        maxHeight: "32px",
                                        minHeight: "32px",
                                        justifyContent: 'flex-start',
                                        padding: 0,
                                        textAlign: 'left',
                                        alignItems: "left"
                                    }}
                                >
                                    {equipment.code}
                                </Button>
                            </td>
                            <td>
                                <Button
                                    onClick={() => handleViewDetails(equipment)}
                                    color={"neutral"}
                                    variant={"plain"}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500, // You can use "bold", "normal", or a numeric value like 700
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            color: "rgb(52, 98, 172, 1)",
                                            textDecoration: "underline"
                                        },
                                        maxHeight: "32px",
                                        minHeight: "32px",
                                        justifyContent: 'flex-start',
                                        padding: 0,
                                        textAlign: 'left',
                                        alignItems: "left"
                                    }}
                                >
                                    {equipment.name}
                                </Button></td>
                            <td>
                                <Chip size={"sm"} color={equipment.manufacturerName !== null ? "primary" : "danger"}>
                                    {equipment.manufacturerName !== null ? equipment.manufacturerName : "Sem fabricante associado"}
                                </Chip>
                            </td>
                            <td>
                                <Chip size={"sm"} color={equipment.productionLineName !== null ? "primary" : "danger"}>
                                    {equipment.productionLineName !== null ? equipment.productionLineName : "Sem linha associada"}
                                </Chip>
                            </td>
                            <td>{equipment.model !== null ? equipment.model : "Indefinido"}</td>
                            <td>{equipment.year !== null ? equipment.year : "Indefinido"}</td>
                            <td>{equipment.serialNumber !== null ? equipment.serialNumber : "Indefinido"}</td>
                            <td style={{textAlign: "right"}}>
                                <Dropdown>
                                    <MenuButton
                                        variant="plain"
                                        size="sm"
                                        sx={{
                                            maxWidth: "30px",
                                            maxHeight: "30px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                        }}
                                    >
                                        <MoreVertIcon/>
                                    </MenuButton>
                                    <Menu placement={"bottom-end"}>
                                        <MenuItem onClick={() => console.log("Edit")}>
                                            <ListItemDecorator>
                                                <EditOutlinedIcon fontSize="small"/>
                                            </ListItemDecorator>
                                            <Typography fontSize="0.8rem">Editar</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => navigate(`plano_manutencao?code=${equipment.code}`)}>
                                            <ListItemDecorator>
                                                <AssignmentOutlinedIcon fontSize="small"/>
                                            </ListItemDecorator>
                                            <Typography fontSize="0.8rem">Ver plano</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Sheet>
            <ViewEquipmentDetailsDialog open={openDetails} setOpen={setOpenDetails} equipment={equipmentDetails}
                                        fetchEquipments={fetchEquipments}/>
            <Pagination fetchData={fetchEquipments} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </>
    )
}

export default EquipmentsTable;