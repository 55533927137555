import {
    BrowserRouter as Router,
    Routes,
    Route, useLocation,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./CustomRoutes/PrivateRoute";
import DashboardPage from "./pages/DashboardPage";
import React, { useEffect, useState } from "react";
import fetchValidation from "./utils/ValidateToken";
import LoginRoute from "./CustomRoutes/LoginRoute";
import SkusPage from "./pages/SkusPage";
import DefinicoesPage from "./pages/DefinicoesPage";
import { SidebarProvider } from "./Contexts/SidebarContext";
import ViewRecipePage from "./pages/ViewRecipePage";
import ListaMateriaisManutencao from "./pages/Materiais/ListMateriaisManutencao";
import ListaMateriaisProducao from "./pages/Materiais/ListMateriaisProducao";
import { useAppContext } from "./Contexts/AppContext";
import { CriarMaterialPage } from "./pages/Materiais/CriarMaterialPage.js";
import { PageProvider } from "./Contexts/PageContext";
import * as signalR from "@microsoft/signalr";
import RoleProtectedRoute from "./CustomRoutes/RoleProtectedRoute";
import AprovarMaterial from "./pages/Materiais/AprovarMaterial";
import MaintenancePlanPage from "./pages/Maintenance/MaintenancePlan";
import MaintenanceOrdersPage from "./pages/Maintenance/MaintenanceOrdersPage";
import ActiveMaintenanceOrdersPage from "./pages/Maintenance/ActiveMaintenanceOrdersPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import MaterialReceiptPage from "./pages/Movements/MaterialReceiptPage";
import NotificationSnackbar from "./components/Notification/NotificationSnackbar";
import useFetchNotifications from "./hooks/fetchNotifications";
import useConnectToSignalR from "./hooks/useConnectToSignalR";
import EditMaintenancePlanPage from "./pages/Maintenance/EditMaintenancePlanPage";
import ProductionLinesPage from "./pages/ProductionLinesPage";
import EquipmentPages from "./pages/EquipmentsPage";


const Layout = ({ children }) => {
    const location = useLocation();
    const hideNotificationOnPaths = ["/", "/reset-password", "/forgot-password"];
    const showNotificationSnackbar = !hideNotificationOnPaths.includes(location.pathname);

    return (
        <>
            {children}
            {showNotificationSnackbar && <NotificationSnackbar />}
        </>
    );
};


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isVerifying, setIsVerifying] = useState(true);
    const { setUserRoles} = useAppContext();
    const [firstLoad, setFirstLoad] = useState(true);

    useFetchNotifications(isLoggedIn); // Pass isLoggedIn state to the hook
    useConnectToSignalR(isLoggedIn); // Pass isLoggedIn state to the hook

    useEffect(() => {
    const initialize = async () => {
      await fetchValidation({ setIsLoggedIn, setUserRoles });
    };

    initialize();
    }, []);

    useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      setIsVerifying(false);
    }
    }, [isLoggedIn]);

    const routes = [
        {
            path: "/dashboard",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <DashboardPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/skus",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <SkusPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/skus/recipe",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <ViewRecipePage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_manutencao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <ListaMateriaisManutencao setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_producao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <ListaMateriaisProducao setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_producao/pedido",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <RoleProtectedRoute allowedRoles={["Chefia", "Dev", "Admin"]}>
                        <AprovarMaterial
                            setIsLoggedIn={setIsLoggedIn}
                            indexSelected={2}
                        />
                    </RoleProtectedRoute>
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_manutencao/pedido",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <RoleProtectedRoute allowedRoles={["Chefia", "Dev", "Admin"]}>
                        <AprovarMaterial
                            setIsLoggedIn={setIsLoggedIn}
                            indexSelected={3}
                        />
                    </RoleProtectedRoute>
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_producao/criar_material/*",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <PageProvider page={"materiais_producao"}>
                        <CriarMaterialPage
                            indexSelected={2}
                            setIsLoggedIn={setIsLoggedIn}
                        />
                    </PageProvider>
                </PrivateRoute>
            ),
        },
        {
            path: "materiais_manutencao/criar_material/*",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <PageProvider page={"materiais_manutencao"}>
                        <CriarMaterialPage
                            indexSelected={3}
                            setIsLoggedIn={setIsLoggedIn}
                        />
                    </PageProvider>
                </PrivateRoute>
            ),
        },
        {
            path: "plano_manutencao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <MaintenancePlanPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            )
        },
        {
            path: "plano_manutencao/editar",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <EditMaintenancePlanPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            )
        },
        {
            path: "ordens_manutencao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <MaintenanceOrdersPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/manutencoes_ativas",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <ActiveMaintenanceOrdersPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/rececao_materiais",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <MaterialReceiptPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/definicoes",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <DefinicoesPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            ),
        },
        {
            path: "/",
            element: (
                <LoginRoute isLoggedIn={isLoggedIn}>
                    <LoginPage setIsLoggedIn={setIsLoggedIn} />
                </LoginRoute>
            ),
        },
        {
            path: "/forgot-password",
            element: (
                <LoginRoute isLoggedIn={isLoggedIn}>
                    <ForgotPasswordPage setIsLoggedIn={setIsLoggedIn} />
                </LoginRoute>
            ),
        },
        {
            path: "/reset-password",
            element: (
                <LoginRoute isLoggedIn={isLoggedIn}>
                    <ResetPasswordPage setIsLoggedIn={setIsLoggedIn} />
                </LoginRoute>
            ),
        },
        {
            path: "/linhas_producao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <ProductionLinesPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            )
        },
        {
            path: "equipamentos",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <EquipmentPages setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            )
        },
        {
            path: "equipamentos/plano_manutencao",
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <MaintenancePlanPage setIsLoggedIn={setIsLoggedIn} />
                </PrivateRoute>
            )
        }
    ]

    if (!isVerifying) {
        return (
            <Router>
                <SidebarProvider>
                    <Layout>
                        <Routes>
                            {routes.map((route) => (
                                <Route key={route.path} path={route.path} element={route.element}/>
                            ))}
                        </Routes>
                    </Layout>
                </SidebarProvider>
            </Router>
        );
    }
}

export default App;
