import * as React from 'react';
import {
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    ModalDialog,
    Modal
} from '@mui/joy';

export default function EditChangesDialog({id, setOpen, open, fetchPlan, handleSave, setEditing, editIndex, setIndexToEdit}) {

    const handleClose = () => {
        setOpen(false);
        setIndexToEdit(null);
    };

    const handleConfirm = async () => {
        await handleSave(id);
        setOpen(false);
        setIndexToEdit(null);
        await fetchPlan();
        setEditing(editIndex);
    };

    return (
        <Stack spacing={2} sx={{zIndex: 100}}>
            <Modal open={open} onClose={handleClose} disableEscapeKeyDown={true} disableBackdropClick={true}>
                <ModalDialog>
                    <DialogTitle>
                        Confirmar edição
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            Pretende guardar as alterações da ordem de manutenção?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="danger" variant={"solid"} onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button color="primary" variant={"soft"} onClick={handleConfirm}>
                            Confirmar
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </Stack>
    );
}