import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';

export const OrderStatus = [
    {
        id: 1,
        name: "Pendente",
        color: "warning",
        value: 0,
        icon: <AutorenewOutlinedIcon/>
    },
    {
        id: 2,
        name: "Em andamento",
        color: "primary",
        value: 1,
        icon: <TimelapseOutlinedIcon/>
    },
    {
        id: 3,
        name: "Concluído",
        color: "success",
        value: 2,
        icon: <CheckCircleOutlinedIcon/>
    },
    {
        id: 4,
        name: "Cancelado",
        color: "danger",
        value: 3,
        icon: <DoDisturbOnOutlinedIcon/>
    }
]

export const GetStatusName = (status) => {
    return OrderStatus.find(x => x.value === status).name;
}

export const GetStatusColor = (status) => {
    return OrderStatus.find(x => x.value === status).color;
}

export const GetStatusIcon = (status) => {
    return OrderStatus.find(x => x.value === status).icon;
}