import {Chip, Dropdown, Menu, MenuButton, MenuItem, Sheet, Table, Typography} from "@mui/joy";
import {GetReceiptStatusColor, GetReceiptStatusIcon, GetReceiptStatusName} from "../../Models/ReceiptStatus";
import Pagination from "../Skus/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function MaterialReceiptTable({materialReceipts, fetchData, currentPage, setCurrentPage, totalPages}) {
    return (
        <>

            <Sheet
                variant="outlined"
                sx={{
                    mt: 2,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}>
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                    }}>
                        <thead>
                            <tr>
                                <th style={{width: "14%", verticalAlign: "middle"}}>Código</th>
                                <th style={{width: "21%", verticalAlign: "middle"}}>Recebedor</th>
                                <th style={{width: "21%", verticalAlign: "middle"}}>Descrição do material</th>
                                <th style={{width: "7%", verticalAlign: "middle", textAlign: 'center'}}>Status</th>
                                <th style={{width: "11%", verticalAlign: "middle", textAlign: 'right'}}>Número do pedido</th>
                                <th style={{width: "10%", verticalAlign: "middle", textAlign: 'right'}}>Entregue</th>
                                <th style={{width: "10%", verticalAlign: "middle", textAlign: 'right'}}>Em falta</th>
                                <th style={{width: "6%", verticalAlign: "middle", textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materialReceipts.map((receipt) => (
                                <tr key={receipt.id} style={{height: "38px"}}>
                                    <td>{receipt.code}</td>
                                    <td>{receipt.receiverName}</td>
                                    <td>{receipt.description}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <Chip variant="soft" size="sm" color={GetReceiptStatusColor(receipt.status)} startDecorator={GetReceiptStatusIcon(receipt.status)}>
                                            {GetReceiptStatusName(receipt.status)}
                                        </Chip>
                                    </td>
                                    <td style={{textAlign: 'right'}}>{receipt.orderNumber}</td>
                                    <td style={{textAlign: 'right'}}>{receipt.delieveredAmount}</td>
                                    <td style={{textAlign: 'right'}}>{receipt.missingAmount}</td>
                                    <td style={{textAlign: 'right'}}>
                                        <Dropdown>
                                            <MenuButton
                                                variant="plain"
                                                size="sm"
                                                sx={{
                                                    maxWidth: "30px",
                                                    maxHeight: "30px",
                                                    minWidth: "30px",
                                                    minHeight: "30px",
                                                }}
                                            >
                                                <MoreVertIcon/>
                                            </MenuButton>
                                            <Menu
                                                sx={{
                                                    gap: 0.5,
                                                    minWidth: "80px",
                                                    maxWidth: "200px",
                                                    minHeight: "40px",
                                                }}
                                                size="sm"
                                            >
                                                <MenuItem>
                                                    <Typography fontSize="0.8rem">Entregar materiais</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                </Table>
            </Sheet>
            <Pagination fetchData={fetchData} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </>
    )
}