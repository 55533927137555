import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export const ReceiptStatus = [
    {
        id: 1,
        name: "Ativa",
        color: "success",
        value: 0,
        icon: <AutorenewOutlinedIcon/>
    },
    {
        id: 2,
        name: "Encerrada",
        color: "primary",
        value: 1,
        icon: <CheckCircleOutlinedIcon/>
    },
]

export const GetReceiptStatusName = (status) => {
    return ReceiptStatus.find(x => x.value === status).name;
}

export const GetReceiptStatusColor = (status) => {
    return ReceiptStatus.find(x => x.value === status).color;
}

export const GetReceiptStatusIcon = (status) => {
    return ReceiptStatus.find(x => x.value === status).icon;
}