import {DialogContent, DialogTitle, Modal, ModalDialog, Stack} from "@mui/joy";
import {CalendarForm} from "../CalendarForm";


export default function PlanDialog({open, setOpen, id, fetchOrders}) {
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Stack>
            <Modal open={open} onClose={handleClose} >
                <ModalDialog sx={{width: "400px"}}>
                    <DialogTitle>
                        Planear manutenção
                    </DialogTitle>
                    <DialogContent>Escolha uma data para efetuar a manutenção</DialogContent>
                    <CalendarForm id={id} fetchOrders={fetchOrders} setOpen={setOpen}/>
                </ModalDialog>
            </Modal>
        </Stack>
    )
}