import Header from "../../components/Skus/Header";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Link, Option,
    Select,
    Typography
} from "@mui/joy";
import BeableBase from "../BeableBase";
import DashboardIcon from "@mui/icons-material/Dashboard";
import React, {useEffect, useState} from "react";
import MaterialReceiptTable from "../../components/Movements/MaterialReceiptTable";
import axiosInstance from "../../utils/axiosInstance";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export default function MaterialReceiptPage({setIsLoggedIn}) {
    const [canLoadPage, setCanLoadPage] = useState(false);
    const [receipts, setReceipts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [pageSize, setPageSize] = useState(16);
    const [searchInput, setSearchInput] = useState("");
    const [searchType, setSearchType] = useState("code");
    const [searchedInput, setSearchedInput] = useState("");
    const [searchedType, setSearchedType] = useState("code");

    const handleSearchReceipts = async (event) => {
        event.preventDefault();

        setCurrentPage(1);
        setSearchedInput(searchInput);
        setSearchedType(searchType);
    }

    const fetchMaterialReceipts = async () => {
        try {
            const response = await axiosInstance
                .get(`movements/search_material_receipts?searchInput=${searchedInput}&searchType=${searchedType}&page=${currentPage}&pageSize=${pageSize}`);

            setReceipts(response.data.receipts)
            setTotalPages(response.data.totalPages)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        fetchMaterialReceipts();
    }, [searchedInput, searchedType, currentPage]);

    useEffect(() => {
        const fetchData = async () => {
            await fetchMaterialReceipts(searchedInput, searchedType);

            setCanLoadPage(true);
        }
        fetchData()
    }, []);

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>Recebimento de materiais</Typography>
        </Link>,
    ];

    return (
        <BeableBase indexSelected={7} setIsLoggedIn={setIsLoggedIn}>
            <Header linkItems={path} />
            <Box
                sx={{
                    width: "100%",
                    mt: 4,
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                }}
            >
                <Typography level="h2">
                    Recebimento de materiais
                </Typography>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <form onSubmit={handleSearchReceipts}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: 2, alignItems: "end"}}>

                        <FormControl size={"sm"} sx={{flex: 2}}>
                            <FormLabel>
                                Procurar pedidos de material
                            </FormLabel>
                            <Input
                                placeholder={`Insira ${searchType === "code" ? "o código" : searchType === "description" ? "a descrição" : "o número de pedido"}`}
                                value={searchInput}
                                onChange={(event) => setSearchInput(event.target.value)}
                                startDecorator={
                                    <React.Fragment>
                                        <FormControl>
                                            <Select
                                                size="sm"
                                                value={searchType}
                                                variant="soft"
                                                onChange={(_, value) => setSearchType(value)}
                                                slotProps={{
                                                    listbox: {
                                                        variant: "outlined",
                                                    },
                                                }}
                                                sx={{ml: -1, "&:hover": {bgcolor: "transparent"}}}
                                            >
                                                <Option value="code">Código</Option>
                                                <Option value="description">Descrição</Option>
                                                <Option value="order_number">Número de pedido</Option>
                                            </Select>
                                        </FormControl>
                                        <Divider orientation="vertical"/>
                                    </React.Fragment>
                                }
                            />
                        </FormControl>
                        <Button type={"submit"} sx={{maxHeight: "34px", minHeight: "34px", maxWidth: "34px", minWidth: "34px"}}>
                            <SearchOutlinedIcon fontSize="lg"/>
                        </Button>
                    </Box>
                </form>
                {
                    canLoadPage === true
                        ? <MaterialReceiptTable
                            materialReceipts={receipts}
                            fetchData={fetchMaterialReceipts}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}/> :
                        (
                            <Box
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                                <CircularProgress/>
                            </Box>
                        )
                }
            </Box>
        </BeableBase>
    )
}