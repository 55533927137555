import {Button, Dropdown, Menu, MenuButton, MenuItem, Sheet, Table, Typography} from "@mui/joy";
import Pagination from "../Skus/Pagination";
import {ConvertToReadableDateNormal} from "../../utils/ConvertToReadableDate";
import PlanDialog from "./PlanDialog";
import {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function MaintenanceOrdersTable({orders, fetchOrders, currentPage, setCurrentPage, totalPages}) {
    const [planDialogOpen, setPlanDialogOpen] = useState(false)
    const [orderToEdit, setOrderToEdit] = useState(null);

    const handleClickPlan = (id) => {
        setOrderToEdit(id)
        setPlanDialogOpen(true);
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    mt: 3,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}>
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                }}>
                    <thead>
                        <tr>
                            <th style={{width: "10%"}}>Linha</th>
                            <th style={{width: "15%"}}>Máquina</th>
                            <th style={{width: "30%"}}>Tarefa</th>
                            <th style={{width: "10%"}}>Data manutenção</th>
                            <th style={{width: "10%"}}>Tipo de atividade</th>
                            <th style={{width: "10%"}}>Quem</th>
                            <th style={{width: "5%"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr style={{height: "38px"}} key={index}>
                                <td>{order.linhaName}</td>
                                <td>{order.machineName}</td>
                                <td>{order.task}</td>
                                <td>{order.planned === null ?
                                    ConvertToReadableDateNormal(order.nextPlanned)
                                    : ConvertToReadableDateNormal(order.planned)}
                                </td>
                                <td>{order.activityType}</td>
                                <td>{order.who}</td>
                                <td>
                                    <Dropdown>
                                        <MenuButton
                                            variant="plain"
                                            size="sm"
                                            sx={{
                                                maxWidth: "30px",
                                                maxHeight: "30px",
                                                minWidth: "30px",
                                                minHeight: "30px",
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </MenuButton>
                                        <Menu
                                            sx={{
                                                minWidth: "80px",
                                                maxWidth: "200px",
                                                minHeight: "40px",
                                            }}
                                            size="sm"
                                        >
                                            <MenuItem onClick={() => handleClickPlan(order.id)}>
                                                <Typography fontSize="0.8rem">Editar data de manutenção</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
            <PlanDialog open={planDialogOpen} setOpen={setPlanDialogOpen} id={orderToEdit} fetchOrders={fetchOrders}/>
            <Pagination fetchData={fetchOrders} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </>
    )
}