import BeableBase from "../BeableBase";
import Header from "../../components/Skus/Header";
import {Box, Link, Typography} from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ActiveOrdersTable from "../../components/Maintenance/ActiveOrdersTable";
import axiosInstance from "../../utils/axiosInstance";
import {useEffect, useState} from "react";

export default function ActiveMaintenanceOrdersPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [canLoadTable, setCanLoadTable] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    const pageSize = 16;

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="active_orders" href="" color="neutral">
            <Typography>Ordens de manutenção</Typography>
        </Link>,
    ];

    const fetchOrders = async () => {
        try {
            const response = await axiosInstance.get(`maintenance/search_active_orders?page=${currentPage}&pageSize=${pageSize}`);

            setOrders(response.data.activeOrders);
            setTotalPages(response.data.totalPages);

            console.log(response.data)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchOrders();
            setCanLoadTable(true);
        }

        fetchData();
    }, []);

    return (
        <BeableBase>
            <Header linkItems={path}/>
            <Box sx={{
                width: "100%",
                mt: 4,
                display: "flex",
                position: "relative",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
            }}>
                <Typography level="h2">
                    Ordens de manutenção ativas
                </Typography>
            </Box>
            <ActiveOrdersTable orders={orders} fetchOrders={fetchOrders} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </BeableBase>
    )
}