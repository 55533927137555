import {Autocomplete, Box, Select, Typography} from "@mui/joy";
import React from "react";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const DetailsSelect = ({text, canEdit, disabled, value, valueText,onChange, children}) => {
    return (
        <Box sx={{justifyContent: "space-between", display: "flex", flexDirection: "row"}}>
            <Typography
                sx={{
                    flex: 2,
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
                fontSize={"0.93rem"}
                level={"body-md"}
            >
                {text}
            </Typography>
            <Select
                endDecorator={canEdit ? null : <Typography>{valueText}</Typography> }
                indicator={canEdit ? <ExpandMoreRoundedIcon/> : null}
                startDecorator={canEdit && null}
                sx={{
                    boxShadow: "none",
                    flex: 3,
                    top: -3,
                    '& select': {
                        textAlign: 'right', // Align text inside select to the right
                    },
                }}
                size={"sm"}
                variant={"soft"}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={true}
            >
                {canEdit && children}

            </Select>
        </Box>
    )
}

export default DetailsSelect;