import {Box, Button, Input} from "@mui/joy";
import {useEffect, useState} from "react";
import {ConvertToReadableDateInverted} from "../utils/ConvertToReadableDate";
import axiosInstance from "../utils/axiosInstance";

export const CalendarForm = ({id, fetchOrders, setOpen}) => {
    const [planDate, setPlanDate] = useState("");
    const [minDate, setMinDate] = useState("");

    useEffect(() => {
        const date = new Date();

        console.log(date);

        setMinDate(ConvertToReadableDateInverted(date));
        setPlanDate(ConvertToReadableDateInverted(date));
    }, []);

    const handleInputChange = (value) => {
        setPlanDate(value);

        console.log(new Date(value).toISOString())
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const body = {
                UpdatePlannedList: [
                    {
                        Id: id,
                        Date: new Date(planDate).toISOString()
                    }
                ]
            };

            const response = await axiosInstance.patch("maintenance/update_planned", body);

            if (response.status === 200)
            {
                await fetchOrders();
                setOpen(false);
            }
            console.log(id)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, )}>
            <Box sx={{display: "flex", flexDirection: "column", width: "px", gap: 3}}>
                <Input
                    sx={{width: "100%"}}
                    value={planDate}
                    onChange={(event) => handleInputChange(event.target.value)}
                    type="date"
                    slotProps={{
                        input: {
                            min: `${minDate}`
                        }
                    }}
                />
                <Button type={"submit"} size={"sm"}>Planear</Button>
            </Box>
        </form>
    );
}