import { useEffect } from "react";
import { useAppContext } from "../Contexts/AppContext";
import * as signalR from "@microsoft/signalr";

const useConnectToSignalR = (isLoggedIn) => {
    const { setNotificationData, setNotificationOpen, fetchNotifications } = useAppContext();

    useEffect(() => {
        if (!isLoggedIn) return;

        const connectToSignalR = async () => {
            const baseURL = process.env.REACT_APP_BASE_URL || "https://materiaisdev.ableconsulting.pt";

            let conn = new signalR.HubConnectionBuilder()
                .withUrl(`${baseURL}/ws/notification-hub`, {
                    accessTokenFactory: () => `${localStorage.getItem("jwt")}`,
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .build();

            await conn.start();

            conn.on("ReceiveNotification", async (msg, url, btnMessage) => {
                setNotificationOpen(true);

                setNotificationData((prevState) => {
                    const newNotification = {
                        message: msg,
                        Url: url,
                        buttonMessage: btnMessage,
                    };

                    const updatedState = { ...prevState, ...newNotification };

                    return updatedState;
                });

                await fetchNotifications();
            });
        };

        connectToSignalR();
    }, [isLoggedIn, setNotificationData, setNotificationOpen, fetchNotifications]);
};

export default useConnectToSignalR;