import {AspectRatio, Box, Card, CardContent, CardOverflow, Sheet, TabPanel, Tabs, Typography} from "@mui/joy";
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import {ConvertToReadableDateNormal} from "../../utils/ConvertToReadableDate";

const ProductionLineCard = ({icon, color, line}) => {
    return (
        <Card
            variant={"outlined"}
            size={"lg"}
            sx={{
                width: "50%",
                minHeight: "350x",
                '--icon-size': '130px',
                transition: 'transform 0.3s',
                '&:hover': {
                    transform: 'translateY(-4px)',
                },
            }}
            orientation={"horizontal"}

        >
            <CardOverflow
                variant={"solid"}
                display={"flex"}
                color={color} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    pl: "30px"
                }}
            >
                <AspectRatio
                    variant="outlined"
                    color={color}
                    ratio="1"
                    sx={{
                        width: 'var(--icon-size)',
                        height: 'var(--icon-size)',
                        transform: 'translateX(50%)',
                        borderRadius: '50%',
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        position: 'relative',
                    }}
                >
                    <div>
                        <img style={{width: "55%", height: "auto"}} src={icon} />
                    </div>
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Box sx={{ml: 10, mr: 2, py: 1, display: "flex", flexDirection: "column", gap: 2}}>
                    <Typography level={"h4"}>{line.name}</Typography>
                    <Sheet
                        sx={{
                            bgcolor: 'background.level1',
                            borderRadius: 'sm',
                            p: 1.5,
                            py: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': {flex: 1},
                        }}>
                        <div>
                            <Typography level={"body-sm"}>Ordens de manutenção: <Typography
                                level={"title-sm"}>{line.maintenanceOrdersCount}</Typography> </Typography>
                        </div>
                        <div>
                            <Typography level={"body-sm"}>Equipamentos: <Typography
                                level={"title-sm"}>{line.equipmentCount}</Typography> </Typography>
                        </div>
                    </Sheet>
                    <Tabs
                        size={"sm"}
                        variant="outlined"
                        aria-label="Pricing plan"
                        defaultValue={0}
                        sx={{
                            width: "100%",
                            borderRadius: 'lg',
                            boxShadow: 'sm',
                            overflow: "auto"
                        }}
                    >
                        <TabList
                            disableUnderline
                            tabFlex={1}
                            sx={{
                                [`& .${tabClasses.root}`]: {
                                    fontSize: 'xs',
                                    fontWeight: 'lg',

                                    [`&[aria-selected="true"]`]: {
                                        color: 'primary.500',
                                        bgcolor: 'background.surface',
                                    },
                                    [`&.${tabClasses.focusVisible}`]: {
                                        outlineOffset: '-4px',
                                    },
                                },
                            }}
                        >
                            <Tab disableIndicator variant="soft"  sx={{ flexGrow: 1 }}>
                                Ordens de manutenção
                            </Tab>
                            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                                Equipamentos
                            </Tab>
                        </TabList>
                        <TabPanel value={0}>
                            <Box sx={{gap: 1, display: "flex", flexDirection: "column", maxWidth: "60%"}}>
                                <Typography level="inherit">
                                    Próxima ordem: <Typography level={"title-sm"}>{ConvertToReadableDateNormal(line.nextMaintenance)}</Typography>
                                </Typography>
                                <Typography level="inherit">
                                    Última ordem: <Typography level={"title-sm"}>{ConvertToReadableDateNormal(line.lastMaintenance)}</Typography>
                                </Typography>
                                <Typography level="inherit">
                                    Ordens ativas: <Typography level={"title-sm"}>{line.activeOrdersCount}</Typography>
                                </Typography>
                                <Typography level="inherit">
                                    Nº de tarefas de manutenção realizadas nos últimos 7 dias: <Typography level={"title-sm"}>{line.maintenaceDoneLastWeekCount}</Typography>
                                </Typography>
                            </Box>
                        </TabPanel>
                        <TabPanel value={1}>
                            <Typography level="inherit">
                                Teste
                            </Typography>

                        </TabPanel>
                    </Tabs>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ProductionLineCard;