import {Autocomplete, Box, Typography} from "@mui/joy";
import React from "react";

const DetailsAutocomplete = ({text, canEdit, disabled, startDecorator, inputValue, onInputChange, value, onChange, options, getOptionLabel, freeSolo}) => {
    return (
        <Box sx={{justifyContent: "space-between", display: "flex", flexDirection: "row"}}>
            <Typography
                sx={{
                    flex: 2,
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
                fontSize={"0.93rem"}
                level={"body-md"}
            >
                {text}
            </Typography>
            <Autocomplete
                freeSolo={freeSolo}
                disableCloseOnSelect
                forcePopupIcon={false}
                startDecorator={canEdit ? startDecorator : null}
                sx={{
                    boxShadow: "none",
                    flex: 3,
                    top: -3,
                    '& .Mui-disabled': {
                        fontWeight: 400,
                        fontSize: "0.93rem",
                        color: 'rgba(0, 0, 0, 1)',
                        textAlign: ( canEdit ? 'left' : 'right' ),

                    },
                }}
                size={"sm"}
                variant={"soft"}
                value={value}
                inputValue={inputValue}
                onChange={onChange}
                onInputChange={onInputChange}
                disabled={disabled}
                options={options}
                getOptionLabel={getOptionLabel}
                required={true}
            />
        </Box>
    )
}

export default DetailsAutocomplete;