import {Chip, Dropdown, Menu, MenuButton, MenuItem, Sheet, Table, Typography} from "@mui/joy";
import Pagination from "../Skus/Pagination";
import {GetStatusColor, GetStatusIcon, GetStatusName} from "../../Models/OrderStatus";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import axiosInstance from "../../utils/axiosInstance";

export default function ActiveOrdersTable({orders, fetchOrders, currentPage, setCurrentPage, totalPages}) {
    const updateActiveOrder = async (id, status) => {
        try {
            await axiosInstance.patch(`maintenance/update_active_order`, {
                id: id,
                status: status
            });

            fetchOrders();
            await fetchOrders();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    mt: 3,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}>
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                    }}
                >
                    <thead>
                    <tr>
                        <th style={{width: "10%"}}>Linha</th>
                        <th style={{width: "15%"}}>Máquina</th>
                        <th style={{width: "20%"}}>Tarefa</th>
                        <th style={{width: "10%"}}>Status</th>
                        <th style={{width: "15%"}}>Tipo de atividade</th>
                        <th style={{width: "15%"}}>Tipo de manutenção</th>
                        <th style={{width: "10%"}}>Quem</th>
                        <th style={{width: "5%"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr style={{height: "38px"}} key={index}>
                                <td>{order.linhaName}</td>
                                <td>{order.machineName}</td>
                                <td>{order.task}</td>
                                <td>
                                    <Chip variant="soft" size="sm" color={GetStatusColor(order.status)} startDecorator={GetStatusIcon(order.status)}>
                                        {GetStatusName(order.status)}
                                    </Chip>
                                </td>
                                <td>{order.activityType}</td>
                                <td>{order.maintenanceType}</td>
                                <td>{order.who}</td>
                                <td>
                                    {(order.status === 0 || order.status === 1) && (
                                        <Dropdown>
                                            <MenuButton
                                                variant="plain"
                                                size="sm"
                                                sx={{
                                                    maxWidth: "30px",
                                                    maxHeight: "30px",
                                                    minWidth: "30px",
                                                    minHeight: "30px",
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </MenuButton>
                                            <Menu
                                                sx={{
                                                    gap: 0.5,
                                                    minWidth: "80px",
                                                    maxWidth: "200px",
                                                    minHeight: "40px",
                                                }}
                                                size="sm"
                                            >
                                                <MenuItem variant="soft" color="primary" onClick={() => updateActiveOrder(order.id, 2)}>
                                                    <Typography fontSize="0.8rem">Finalizar</Typography>
                                                    <CheckCircleOutlinedIcon fontSize="small"/>
                                                </MenuItem>
                                                <MenuItem variant="soft" color="danger" onClick={() => updateActiveOrder(order.id, 3)}>
                                                    <Typography fontSize="0.8rem">Cancelar</Typography>
                                                    <DoDisturbOnOutlinedIcon fontSize="small"/>
                                                </MenuItem>
                                            </Menu>
                                        </Dropdown>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
            <Pagination fetchData={fetchOrders} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        </>
    )
}