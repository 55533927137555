import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Typography } from "@mui/joy";
import { useEffect, useState } from "react";

function PaginationButton({
    value,
    isSelected,
    fetchData,
    setCurrentPage,
}) {
  const handleMouseDown = async () => {
    try {
      await fetchData(value, 16);
      setCurrentPage(value);
    } catch (error) {
      console.error("Error fetching skus:", error);
    }
  };

  return (
    <Button
      onMouseDown={handleMouseDown}
      color="neutral"
      size="sm"
      variant={isSelected ? "soft" : "outlined"}
    >
      {value}
    </Button>
  );
}

export default function Pagination({
  totalPages,
  fetchData,
  currentPage,
  setCurrentPage,
}) {
  const [pages, setPages] = useState([]);
  const [prevDisabled, setPrevDisabled] = useState();
  const [nextDisabled, setNextDisabled] = useState();

  const handleMouseDownPrev = async () => {
    try {
      await fetchData(currentPage - 1, 16);
      setCurrentPage(currentPage - 1);
    } catch (error) {
      console.error("Error fetching skus:", error);
    }
  };

  const handleMouseDownNext = async () => {
    try {
      await fetchData(currentPage + 1, 16);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.error("Error fetching skus:", error);
    }
  };

    useEffect(() => {
        const components = [];
        const maxPagesToShow = 5; // Adjust the max pages to show at a time

        const startPage = Math.max(2, currentPage - 1); // Start range after the first page
        const endPage = Math.min(totalPages - 1, currentPage + 1); // End range before the last page

        // First page is always shown
        components.push(
            <PaginationButton
                setCurrentPage={setCurrentPage}
                fetchData={fetchData}
                isSelected={currentPage === 1}
                key={1}
                value={1}
            />
        );

        // Ellipsis if there's a gap between first page and start page
        if (startPage > 2) {
            components.push(
                <Button key={"ellipsisStart"} variant="plain" disabled={true}>
                    ...
                </Button>
            );
        }

        // Pages in the middle (dynamic range)
        for (let page = startPage; page <= endPage; page++) {
            components.push(
                <PaginationButton
                    setCurrentPage={setCurrentPage}
                    fetchData={fetchData}
                    isSelected={page === currentPage}
                    key={page}
                    value={page}
                />
            );
        }

        // Ellipsis if there's a gap between end page and last page
        if (endPage < totalPages - 1) {
            components.push(
                <Button key={"ellipsisEnd"} variant="plain" disabled={true}>
                    ...
                </Button>
            );
        }

        // Last page is always shown
        components.push(
            <PaginationButton
                setCurrentPage={setCurrentPage}
                fetchData={fetchData}
                isSelected={currentPage === totalPages}
                key={totalPages}
                value={totalPages}
            />
        );

        setPages(components);
    }, [currentPage, totalPages]);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        onMouseDown={handleMouseDownPrev}
        disabled={prevDisabled}
        color="neutral"
        variant="outlined"
        size="sm"
        startDecorator={<KeyboardArrowLeftIcon fontSize="lg" />}
      >
        <Typography fontSize="0.8rem">Anterior</Typography>
      </Button>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>{pages}</Box>
      <Button
        onMouseDown={handleMouseDownNext}
        disabled={nextDisabled}
        color="neutral"
        variant="outlined"
        size="sm"
        endDecorator={<KeyboardArrowRightIcon fontSize="lg" />}
      >
        <Typography fontSize="0.8rem">Seguinte</Typography>
      </Button>
    </Box>
  );
}
