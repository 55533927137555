import {
    Box,
    Button, Card, CardContent,
    Checkbox,
    CssBaseline,
    CssVarsProvider,
    Input,
    Link,
    Stack,
    Typography,
} from "@mui/joy";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function ForgotPasswordPage({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  async function fetchForgotPassword() {
      const baseURL =
          process.env.REACT_APP_BASE_URL ||
          "https://materiaisdev.ableconsulting.pt";
    try {
        const response = await axios.post(
          `${baseURL}/api/auth/forgot-password`,
          {
            email: email,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status === 200) {
            setSuccess(true);
        }
    } catch (error) {
      console.error("Error during forgot password:", error);
    }
  }


  if (success) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <CssVarsProvider>
          <CssBaseline>
            <Box
              backgroundColor="rgba(22 24 47 / 1)"
              width="100%"
              position="relative"
              sx={{
                px: 6,
                py: 3,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    color: "neutral.50",
                  }}
                  level="h1"
                >
                  LOGO
                </Typography>
              </Box>
              <Card
                  variant="solid"
                  color="primary"
                  invertedColors
                sx={{
                  my: "auto",
                  mx: "auto",
                  display: "flex",
                  flexDirection: "column",
                  width: 400,
                  maxWidth: "100%",
                  borderRadius: "sm",
                  verticalAlign: "middle",
                }}
              >
                  <CardContent sx={{gap: 6}}>
                    <Typography level="h3" sx={{ color: "neutral.50" }}>
                      E-mail enviado
                    </Typography>
                    <Typography level="body-md" sx={{ color: "neutral.50" }}>
                      Foi enviado um e-mail para a sua caixa de entrada com as instruções para recuperar a sua conta
                    </Typography>
                    <Button
                        onClick={() => navigate("/")}
                        size="md"
                    >
                        Voltar para o login
                    </Button>
                  </CardContent>
              </Card>
              <Box component="footer" sx={{ display: "flex", mt: "auto" }}></Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              sx={{
                mx: "auto",
                px: 6,
                py: 3,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
              }}
            ></Box>
          </CssBaseline>
        </CssVarsProvider>
      </div>
    );
  } else {

      return (
        <div style={{ display: "flex", height: "100vh" }}>
          <CssVarsProvider>
            <CssBaseline>
              <Box
                backgroundColor="rgba(22 24 47 / 1)"
                width="100%"
                position="relative"
                sx={{
                  px: 6,
                  py: 3,
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{}}>
                  <Typography
                    sx={{
                      color: "neutral.50",
                    }}
                    level="h1"
                  >
                    LOGO
                  </Typography>
                </Box>
                <Box
                  className="LoginForm"
                  sx={{
                    my: "auto",
                    mx: "auto",
                    py: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    width: 400,
                    maxWidth: "100%",
                    borderRadius: "sm",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography level="h3" sx={{ color: "neutral.50" }}>
                    Esqueceu a senha?
                  </Typography>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      fetchForgotPassword();
                    }}
                  >
                      <Stack sx={{ gap: 3 }}>
                      <Typography level="body-md" sx={{color: "neutral.50"}}>Insira o seu e-mail para recuperar a sua conta</Typography>
                        <Stack sx={{ gap: 5 }}>
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            startDecorator={<PersonIcon fontSize="small" />}
                            placeholder="E-mail"
                          />
                        </Stack>
                      <Button type="submit" size="md">
                          Pedir nova senha
                      </Button>
                    </Stack>
                  </form>
                </Box>
                <Box component="footer" sx={{ display: "flex", mt: "auto" }}></Box>
              </Box>
              <Box
                display="flex"
                width="100%"
                sx={{
                  mx: "auto",
                  px: 6,
                  py: 3,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
                }}
              ></Box>
            </CssBaseline>
          </CssVarsProvider>
        </div>
      );
  }
}

export default ForgotPasswordPage;
