import {
    Box,
    Button, Card, CardContent,
    Checkbox,
    CssBaseline,
    CssVarsProvider,
    Input,
    Link,
    Stack,
    Typography,
} from "@mui/joy";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function ResetPasswordPage({ setIsLoggedIn }) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [showRepeatPassword, setShowRepeatPassword] = useState("password");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  async function resetPassword() {
      const email = urlParams.get('email');
      let token = urlParams.get('token');
      token = token.replace(/ /g, "+")

      const baseURL =
          process.env.REACT_APP_BASE_URL ||
          "https://materiaisdev.ableconsulting.pt";

      if (password !== repeatPassword) {
          alert("As palavras-passe não coincidem");
          return;
      }
      try {
          const response = await axios.post(
              `${baseURL}/api/auth/reset-password`,
              {
                  email: email,
                  token: token,
                  password: password,
              },

          );
          if (response.status === 200) {
              setSuccess(true);
          }
      } catch (error) {
          console.error("Error resetting password:", error);
      }
  }

  const showPasswordHandler = () => {
        if (showPassword === "password") {
            setShowPassword("text");
        } else {
            setShowPassword("password");
        }
  }

  const showRepeatPasswordHandler = () => {
        if (showRepeatPassword === "password") {
            setShowRepeatPassword("text");
        } else {
            setShowRepeatPassword("password");
        }
  }

  if (success) {
    return (
      <div style={{ display: "flex", height: "100vh" }}>
        <CssVarsProvider>
          <CssBaseline>
            <Box
              backgroundColor="rgba(22 24 47 / 1)"
              width="100%"
              position="relative"
              sx={{
                px: 6,
                py: 3,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    color: "neutral.50",
                  }}
                  level="h1"
                >
                  LOGO
                </Typography>
              </Box>
              <Card color="primary" variant="solid" invertedColors
                sx={{
                  my: "auto",
                  mx: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  width: 400,
                  maxWidth: "100%",
                  borderRadius: "sm",
                  verticalAlign: "middle",
                }}
              >
                  <CardContent sx={{gap: 3}}>
                    <Typography level="h3" sx={{ color: "neutral.50" }}>
                      Palavra-passe alterada com sucesso
                    </Typography>
                      <Typography level="body-sm" sx={{ color: "neutral.50" }}>
                        A sua palavra-passe foi alterada com sucesso. Pode agora voltar ao login.
                      </Typography>
                    <Stack sx={{ gap: 5 }}>
                      <Button
                        onClick={() => {
                          navigate("/");
                        }}
                        size="md"
                      >
                        Voltar ao login
                      </Button>
                    </Stack>
                  </CardContent>
              </Card>
              <Box component="footer" sx={{ display: "flex", mt: "auto" }}></Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              sx={{
                mx: "auto",
                px: 6,
                py: 3,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
              }}
            ></Box>
          </CssBaseline>
        </CssVarsProvider>
      </div>
    );
  } else {
      return (
        <div style={{ display: "flex", height: "100vh" }}>
          <CssVarsProvider>
            <CssBaseline>
              <Box
                backgroundColor="rgba(22 24 47 / 1)"
                width="100%"
                position="relative"
                sx={{
                  px: 6,
                  py: 3,
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{}}>
                  <Typography
                    sx={{
                      color: "neutral.50",
                    }}
                    level="h1"
                  >
                    LOGO
                  </Typography>
                </Box>
                <Box
                  className="LoginForm"
                  sx={{
                    my: "auto",
                    mx: "auto",
                    py: 5,
                    pb: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    width: 400,
                    maxWidth: "100%",
                    borderRadius: "sm",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography level="h3" sx={{ color: "neutral.50" }}>
                    Alterar palavra-passe
                  </Typography>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      resetPassword();
                    }}
                  >
                    <Stack sx={{ gap: 5 }}>
                      <Stack sx={{ gap: 4 }}>
                          <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword}
                            startDecorator={<LockIcon fontSize="lg" />}
                            endDecorator={
                              <Button color="neutral" variant="plain" onClick={() => showPasswordHandler()}>
                                  {showPassword === "password" ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                              </Button>}
                            placeholder="Palavra-passe"
                          />
                          <Input
                              value={repeatPassword}
                              onChange={(e) => setRepeatPassword(e.target.value)}
                              type={showRepeatPassword}
                              startDecorator={<LockIcon fontSize="lg" />}
                              endDecorator={
                                <Button color="neutral" variant="plain" onClick={() => showRepeatPasswordHandler()}>
                                    {showRepeatPassword === "password" ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                </Button>}
                              placeholder="Repita a palavra-passe"
                          />
                      </Stack>
                      <Button type="submit" size="md">
                        Alterar palavra-passe
                      </Button>
                    </Stack>
                  </form>
                </Box>
                <Box component="footer" sx={{ display: "flex", mt: "auto" }}></Box>
              </Box>
              <Box
                display="flex"
                width="100%"
                sx={{
                  mx: "auto",
                  px: 6,
                  py: 3,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
                }}
              ></Box>
            </CssBaseline>
          </CssVarsProvider>
        </div>
      );
  }

}

export default ResetPasswordPage
