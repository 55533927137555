import {Autocomplete, Box, Button, FormControl, FormLabel, Input} from "@mui/joy";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, {useEffect, useRef, useState} from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axiosInstance from "../../utils/axiosInstance";

export default function AddMaterialFormComponent({materials, setMaterials, materialType, disabled}) {
    const [searchedMaterials, setSearchedMaterials] = useState([]);
    const [materialsNamesLoading, setMaterialsNamesLoading] = useState(false);
    const abortControllerRef = useRef(null);

    useEffect(() => {
        // handleAddMaterial();
        fetchMaterials(0, "")
    }, []);


    const handleAddMaterial = async () => {
        let newMaterial = {item: {name: "", id: 0}, amount: ""}
        setMaterials((prevMaterials) => {
            return [...prevMaterials, newMaterial];
        });
    }

    const handleRemoveMaterial = (index) => {
        setMaterials((prevMaterials) => {
            const updatedMaterials = [...prevMaterials];
            updatedMaterials.splice(index, 1)
            return updatedMaterials;
        });
    }

    const handleOnChangeValue = (index, field, value) => {
        if (field === "item") {
            if (value){
                setMaterials((prevMaterials) => {
                    const updatedMaterials = [...prevMaterials];
                    updatedMaterials[index][field].name = value.name;
                    updatedMaterials[index][field].id = value.id
                    return updatedMaterials;
                })
            }
            return;
        }
        setMaterials((prevMaterials) => {
            const updatedMaterials = [...prevMaterials];
            updatedMaterials[index][field] = value;
            return updatedMaterials;
        })
    }

    const fetchMaterials = async (index, searchString) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const newAbortController = new AbortController();

        abortControllerRef.current = newAbortController;

        setMaterialsNamesLoading(true);
        await axiosInstance
            .get(`materials/search_materials?searchString=${searchString}&searchType=0&materialType=${materialType}&page=1&pageSize=10`, {
                    signal: newAbortController.signal
            }).then((response) => {
                const updatedSearchedMaterials = response.data.materials.map((material) => {
                    return {
                        name: material.name,
                        id: material.id
                    };
                });
                setSearchedMaterials(updatedSearchedMaterials);
                setMaterialsNamesLoading(false);
            })
            .catch((e) => {
                if (e.name !== "AbortError"){
                    console.error(e);
                    setMaterialsNamesLoading(false);
                }
            });
    }

    useEffect(() => {
        console.log(materials)
    }, [materials]);

    return (
        <Box sx={{flexDirection: "column", display: "flex"}}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2}}>
                {materials.map((material, index) => (
                    <Box key={index} sx={{flexDirection: "row", display: "flex", gap: 2, alignItems: "flex-end"}}>
                        <FormControl sx={{width: "70%"}}>
                            {index === 0 && <FormLabel>Material</FormLabel>}
                            <Autocomplete
                                // disabled={disabled}
                                placeholder="Insira o material"
                                // required
                                loading={materialsNamesLoading}
                                value={material.item}
                                onInputChange={async (e, newValue) => await fetchMaterials(index, newValue)}
                                onChange={(e, newValue) => {handleOnChangeValue(index, "item", newValue)}}
                                options={searchedMaterials}
                                getOptionLabel={(option) => option.name}
                            />
                        </FormControl>
                        <FormControl sx={{width: "30%"}}>
                            {index === 0 && <FormLabel>Quantidade</FormLabel>}
                            <Input
                                // disabled={disabled}
                                placeholder="Insira a quantidade"
                                // required
                                value={material.amount}
                                onChange={(e) => {handleOnChangeValue(index, "amount", e.target.value)}}
                                type="number"
                                min={"0"}
                            />
                        </FormControl>
                        <Button
                            // disabled={materials.length <= 1}
                            onClick={(e) => {
                                handleRemoveMaterial(index);
                            }}
                            variant="outlined"
                            color="neutral"
                            sx={{
                                maxWidth: "36px",
                                maxHeight: "36px",
                                minWidth: "36px",
                                minHeight: "36px",
                            }}
                        >
                            <DeleteOutlinedIcon />
                        </Button>
                    </Box>
                ))}
            </Box>
            <Button
                // disabled={disabled}
                onClick={handleAddMaterial}
                sx={{alignSelf: "flex-start", mt: 1}}
                size="sm"
                startDecorator={<AddOutlinedIcon />}
                variant="plain"
            >
                Adicionar material
            </Button>
        </Box>
    )
}