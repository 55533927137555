import * as React from 'react';
import {
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    ModalDialog,
    Modal
} from '@mui/joy';
import axiosInstance from "../../utils/axiosInstance";

export default function RemoveConfirmationDialog({id, setOpen, open, fetchPlan}) {

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        try {
           await axiosInstance.delete(`maintenance/remove_order/${id}`);

           await fetchPlan();
        } catch (e) {
            console.error(e);
        }

        console.log("Maintenance order removed");
        setOpen(false);
    };

    return (
        <Stack spacing={2} sx={{zIndex: 100}}>
            <Modal open={open} onClose={handleClose}>
                <ModalDialog>
                    <DialogTitle>
                        Confirmar remoção
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            Tem a certeza que pretende remover esta ordem de manutenção?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="danger" variant={"solid"} onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button color="primary" variant={"soft"} onClick={handleConfirm}>
                            Confirmar
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </Stack>
    );
}