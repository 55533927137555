import {
    Alert,
    Box, Button,
    DialogContent,
    DialogTitle,
    FormControl, FormLabel,
    Input,
    Modal,
    ModalDialog,
    ModalOverflow,
    Sheet, Textarea,
    Typography
} from "@mui/joy";
import React, {useEffect, useState} from "react";
import {ConvertToReadableDateInverted} from "../../utils/ConvertToReadableDate";
import VerifyInput from "../../utils/VerifyInput";
import axiosInstance from "../../utils/axiosInstance";
import WarningIcon from '@mui/icons-material/Warning';

export default function MakeMaterialMovementOrderDialog({ open, setOpen, material, setSnackbarOpen }) {
    const [body, setBody] = useState({quantity: "", date: ""});
    const [minDate, setMinDate] = useState("");
    const [shouldAlert, setShouldAlert] = useState(false);

     const handleAskMaterial = async (event) => {
        event.preventDefault();
        try {
            const postBody = {
                RequiredAmount: body.quantity,
                RequiredDate: new Date(body.date).toISOString(),
                MaterialId: Number(material.id),
                ForceCreation: shouldAlert
            }

            const response = await axiosInstance.post("movements/create_movement_order", postBody)

            if (response.status === 200) {
                handleClose();
                setSnackbarOpen(true);
            }
            else if (response.status === 202) {
                if (response.data.signal === 1) {
                    setShouldAlert(true);
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const handleClose = () => {
        setBody({quantity: "", date: minDate});
        setOpen(false);
        setShouldAlert(false);
    }

    const handleSetBody = (field, value) => {
        if (field === "quantity") {
            if (VerifyInput(value, "positiveNumber") === false) {
                return;
            }
        }

        setBody((prevState) => {
            const updatedBody = {...prevState};
            updatedBody[field] = value;
            return updatedBody;
        });
    }

    useEffect(() => {
        const date = new Date();

        setMinDate(ConvertToReadableDateInverted(date));
        handleSetBody("date", ConvertToReadableDateInverted(date));
    }, []);

    return (
        <Sheet>
            <Modal
                open={open}
                onClose={() => handleClose()}
                keepMounted={false}
            >
                <ModalDialog sx={{
                    minWidth: "580px",
                    maxWidth: "580px"
                }}>
                    <DialogTitle>
                        Pedir
                    </DialogTitle>
                    <DialogContent>
                        <Typography level={"title-md"}>{material.code} - {material.name}</Typography>
                        <form onSubmit={(e) => handleAskMaterial(e)}>
                            <Box sx={{mt: 2, gap: 2, display: "flex", flexDirection: "column"}}>
                                {shouldAlert && (
                                    <Alert color={"warning"} startDecorator={<WarningIcon/>}>
                                        <Typography level={"title-sm"}>
                                            A quantidade inserida atinge o limite do stock de segurança.
                                            Deseja pedir o material mesmo assim?</Typography>
                                    </Alert>
                                )}
                                <Box sx={{gap: 2, display: "flex", flexDirection: "row"}}>
                                    <FormControl sx={{"flex": 1}}>
                                        <FormLabel>Quantidade necessária</FormLabel>
                                        <Input
                                            required
                                            type="number"
                                            onChange={(event) => handleSetBody("quantity", event.target.value)}
                                            value={body.quantity}
                                            placeholder={"Insira a quantidade necessária"}
                                        />
                                    </FormControl>
                                    <FormControl sx={{"flex": 1}}>
                                        <FormLabel>Data necessária</FormLabel>
                                        <Input
                                            required
                                            value={body.date}
                                            onChange={(event) => handleSetBody("date", event.target.value)}
                                            type="date"
                                            slotProps={{
                                                input: {
                                                    min: `${minDate}`
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Button type={"submit"} size={"sm"}>Pedir material</Button>
                            </Box>
                        </form>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </Sheet>
    )
}