    import BeableBase from "../BeableBase";
import {Box, Button, FormControl, Link, Sheet, Table, Typography, TextField, Input, Option, Select} from "@mui/joy";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import Header from "../../components/Skus/Header";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RemoveConfirmationDialog from "../../components/Maintenance/RemoveConfirmationDialog";
import EditChangesDialog from "../../components/Maintenance/EditChangesDialog";
import {activityType, frequencyUnit, Whos} from "../../Models/MaintenanceModels";
import CreateMaintenanceOrderDialog from "../../components/Maintenance/CreateMaintenanceOrderDialog";

export default function EditMaintenancePlanPage(){
    const [orders, setOrders] = useState([]);
    const [canLoadPage, setCanLoadPage] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const [openEditChanges, setOpenEditChanges] = useState(false)
    const [editing, setEditing] = useState(null);
    const [indexToEdit, setIndexToEdit] = useState(null)
    const [linhas, setLinhas] = useState([]);
    const [machines, setMachines] = useState([]);
    const [editBody, setEditBody] = useState({LinhaId: null, MachineId: null, Task: null, ActivityType: null, Who: null, FrequencyCode: null});
    const [openCreateOrder, setOpenCreateOrder] = useState(false);
    const [frequencies, setFrequencies] = useState([]);

    const path = [
        <Link key="dashboard" href="/dashboard" color="neutral">
            <DashboardIcon fontSize="small" />
        </Link>,
        <Link key="plan" href="/plano_manutencao" color="neutral">
            <Typography>Plano de manutenção</Typography>
        </Link>,
        <Link key="plan" href="" color="neutral">
            <Typography>Editar</Typography>
        </Link>,
    ];

    const handleClickOpenRemove = () => {
        setOpenRemove(true);
    };

    const handleEdit = async (index, id) => {
        if (editing !== null) {
            setOpenEditChanges(true);
            return;
        }
        setEditing(index);
        setIndexToEdit(null);
    }

    const handleSetEditingBody = (field, value) => {
        setEditBody((prevState) => {
            const updatedBody = {...prevState};
            updatedBody[field] = value;
            return updatedBody;
        });

        console.log(editBody);
    }

    const handleAdd = (item) => {
        const newOrder =
            {Id: item.id, MachineName: item.machineName, LinhaId: item.linhaId, LinhaName: item.linhaName,
                MachineId: item.machineId, Task: item.task, FrequencyCount: item.frequencyCount,
                Frequency: item.frequency, FrequencyCode: item.frequencyCode, ActivityType: item.activityType, ActivityTypeValue: item.activityTypeValue,
                WhoValue: item.whoValue, Who: item.who
            };
        setOrders(prevState => {
            const updatedOrders =  [...prevState, newOrder]
            return updatedOrders;
        })
    }

    const handleCancelSave = async () => {
        setEditing(null);
        setEditBody({LinhaId: null, EquipmentId: null, Task: null, ActivityType: null, Who: null})
    }

    const handleSave = async (id) => {
        try {
            const body = {
                LinhaId: editBody.LinhaId,
                MachineId: editBody.MachineId,
                Task: editBody.Task,
                ActivityType: editBody.ActivityType,
                Who: editBody.Who,
                FrequencyCode: editBody.FrequencyCode
            };
            await axiosInstance.put(`maintenance/edit_order/${id}`, body);

            await fetchPlan();

            setEditing({LinhaId: null, MachineId: null, Task: null, ActivityType: null, Who: null, FrequencyCode: null});
        } catch (e) {
            console.error(e)
        }
    }

    const fetchPlan = async () => {
        try {
            const response = await axiosInstance.get(`/maintenance/get_plan`);

            setOrders([]);
            response.data.map((order) => (
                handleAdd(order)
            ));
            console.log(response.data)
        } catch (e) {
            console.log(e.error);
        }
    }

    const fetchLinhas = async () => {
        try {
            const response = await axiosInstance.get("linhas/get_linhas");
            setLinhas(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchMachines = async () => {
        try {
            const response = await axiosInstance.get("machines/get_all");
            setMachines(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    const fetchFrequencies = async () => {
        try {
            const response = await axiosInstance.get("maintenance/get_frequencies");
            setFrequencies(response.data);
        } catch (e) {
            console.error(e)
        }
    }

    const handleAddOrder = () => {
        setOpenCreateOrder(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchPlan();
            await fetchLinhas();
            await fetchMachines()
            await fetchFrequencies();

            setCanLoadPage(true);
        }
        fetchData();
    }, []);

    useEffect( () => {
        if (indexToEdit !== null)
            handleEdit(indexToEdit)
    }, [indexToEdit]);

    return (
        <BeableBase>
            <Header linkItems={path} />
            {
                canLoadPage &&
                <Box sx={{alignSelf: "left", width: "100%", display: "flex", flexDirection: "column"}}>
                    <Box
                        sx={{
                            width: "100%",
                            mt: 4,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 4,
                        }}
                    >
                        <Typography level="h2">Editar plano de manutenção</Typography>
                        <Button size={"sm"} sx={{alignSelf: "flex-start"}} onClick={() => handleAddOrder()}>
                            Adicionar ordem
                        </Button>
                    </Box>
                    <Sheet variant="outlined" sx={{
                        heigth: "100%",
                        mb: 3,
                        borderRadius: "sm",
                        '--TableCell-height': '30px',
                        '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                        '--Table-lastColumnWidth': '174px',
                        overflow: "auto"

                    }}>
                        <Table
                            size="sm"
                            borderAxis="bothBetween"
                            sx={{
                                tableLayout: "fixed",
                                "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                                "--Table-headerUnderlineThickness": "1px",
                                "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                                "--TableCell-paddingY": "4px",
                                "--TableCell-paddingX": "8px",
                                '& tr > *:last-child': {
                                    position: 'sticky',
                                    right: 0,
                                    bgcolor: 'var(--TableCell-headBackground)',
                                },
                            }}>
                            <thead>
                            <tr>
                                <th style={{ width: 150 }}>Linha</th>
                                <th style={{ width: 150 }}>Máquina</th>
                                <th style={{ width: 350 }}>Tarefa</th>
                                <th style={{ width: 150 }}>Frequência</th>
                                <th style={{ width: 150 }}>Tipo de atividade</th>
                                <th style={{ width: 150 }}>Quem</th>
                                <th
                                    aria-label="last"
                                    style={{width: 'var(--Table-lastColumnWidth)'}}
                                />
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order, index) => (
                                <>
                                    {editing !== index
                                        ?   <tr>
                                            <td>{order.LinhaName}</td>
                                            <td>{order.MachineName}</td>
                                            <td>{order.Task}</td>
                                            <td>{order.Frequency}</td>
                                            <td>{order.ActivityType}</td>
                                            <td>{order.Who}</td>
                                            <td>
                                                <Box sx={{display: 'flex', gap: 1, justifyContent: "space-evenly"}}>
                                                    <Button size="sm" variant="soft" color="primary" onClick={() => setIndexToEdit(index)}>
                                                        Editar
                                                    </Button>
                                                    <Button size="sm" variant="soft" color="danger" onClick={handleClickOpenRemove}>
                                                        Remover
                                                    </Button>
                                                </Box>
                                            </td>
                                        </tr>
                                        : <tr>
                                            <td>
                                                <Select
                                                    size={"sm"}
                                                    defaultValue={order.LinhaId}
                                                    onChange={(event, newValue) => {
                                                        handleSetEditingBody("LinhaId", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {linhas?.map((linha) => (
                                                        <Option key={linha.id} value={linha.id}>
                                                            {linha.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </td>

                                            <td>
                                                <Select
                                                    size={"sm"}
                                                    defaultValue={order.MachineId}
                                                    onChange={(event, newValue) => {
                                                        handleSetEditingBody("MachineId", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {machines?.map((machine) => (
                                                        <Option key={machine.id} value={machine.id}>
                                                            {machine.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Input size={"sm"} defaultValue={order.Task} onChange={(e) => {
                                                    handleSetEditingBody("Task", e.target.value);
                                                }}/>
                                            </td>
                                            <td>
                                                <Select
                                                    size={"sm"}
                                                    defaultValue={order.FrequencyCode}
                                                    onChange={(event, newValue) => {
                                                        handleSetEditingBody("FrequencyCode", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {frequencies?.map((frequency) => (
                                                        <Option key={frequency.code} value={frequency.code}>
                                                            {frequency.description}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Select
                                                    size={"sm"}
                                                    defaultValue={order.ActivityTypeValue}
                                                    onChange={(event, newValue) => {
                                                        handleSetEditingBody("ActivityType", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {activityType?.map((type) => (
                                                        <Option key={type.Value} value={type.Value}>
                                                            {type.Name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Select
                                                    size={"sm"}
                                                    defaultValue={order.WhoValue}
                                                    onChange={(event, newValue) => {
                                                        handleSetEditingBody("Who", newValue);
                                                    }}
                                                    sx={{
                                                        color: "neutral",
                                                    }}
                                                    slotProps={{
                                                        listbox: {
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {Whos?.map((who) => (
                                                        <Option key={who.Value} value={who.Value}>
                                                            {who.Name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Box sx={{display: 'flex', gap: 1, justifyContent: "space-evenly"}}>
                                                    <Button size="sm" variant="soft" color="primary" onClick={async () => {
                                                        setEditing(null);
                                                        await handleSave(order.Id)
                                                    }}>
                                                        Guardar
                                                    </Button>
                                                    <Button size="sm" variant="soft" color="danger"
                                                            onClick={handleCancelSave}>
                                                        Cancelar
                                                    </Button>
                                                </Box>
                                            </td>
                                        </tr>
                                    }
                                    <RemoveConfirmationDialog setOpen={setOpenRemove}
                                                              open={openRemove}
                                                              fetchPlan={fetchPlan}
                                                              id={order.Id}/>
                                    <EditChangesDialog setOpen={setOpenEditChanges}
                                                       open={openEditChanges}
                                                       fetchPlan={fetchPlan}
                                                       id={order.Id}
                                                       handleSave={handleSave}
                                                       setEditing={setEditing}
                                                       editIndex={indexToEdit}
                                                       setIndexToEdit={setIndexToEdit}
                                    />
                                </>
                            ))}
                            </tbody>
                        </Table>
                    </Sheet>
                    <CreateMaintenanceOrderDialog open={openCreateOrder} setOpen={setOpenCreateOrder} fetchOrders={fetchPlan} type="plan"/>
                </Box>
            }
        </BeableBase>
    );
}