export function ConvertToReadableDateNormal(date) {
    const convertDate = new Date(date);
    const day = String(convertDate.getDate()).padStart(2, '0');
    const month = String(convertDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = convertDate.getFullYear();

    return `${day}/${month}/${year}`;
}

export function ConvertToReadableDateInverted(date) {
    const convertDate = new Date(date);
    const day = String(convertDate.getDate()).padStart(2, '0');
    const month = String(convertDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = convertDate.getFullYear();

    return `${year}-${month}-${day}`;
}