import Sidebar from "../components/Sidebar";
import {Box, CssBaseline, CssVarsProvider, Sheet} from "@mui/joy";

export default function BeableBase({children, indexSelected, setIsLoggedIn}) {
    return (
        <Sheet style={{ display: "flex", height: "100vh", width: "100vw"}}>
            <Sidebar setIsLoggedIn={setIsLoggedIn} indexSelected={indexSelected}/>
            <CssVarsProvider>
                <CssBaseline>
                    <Box
                        sx={{
                            ml: "var(--Sidebar-width)",
                            px: 5,
                            width: "100%",
                            py: 3.5,
                            overflow: "auto"
                        }}
                    >
                        {children}
                    </Box>
                </CssBaseline>
            </CssVarsProvider>
        </Sheet>
    );
}