import {Sheet, Table} from "@mui/joy";
import {ConvertToReadableDateNormal} from "../../utils/ConvertToReadableDate";


export default function MaintenancePlanTable({orders}) {
    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    mt: 3,
                    width: 1,
                    borderRadius: "sm",
                    overflow: "auto",
                    mb: 3
                }}>
                <Table
                    size="sm"
                    stickyHeader
                    sx={{
                        "--TableCell-headBackground": "var(--joy-palette-background-level1)",
                        "--Table-headerUnderlineThickness": "1px",
                        "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
                        "--TableCell-paddingY": "4px",
                        "--TableCell-paddingX": "8px",
                    }}>
                    <thead>
                        <tr>
                            <th style={{width: "10%"}}>Linha</th>
                            {/*<th style={{width: "20%"}}>Máquina</th>*/}
                            <th style={{width: "20%"}}>Tarefa</th>
                            <th style={{width: "10%"}}>Próxima esperada</th>
                            <th style={{textAlign: "center", width: "10%"}}>Frequência</th>
                            <th style={{textAlign: "right", width: "10%"}}>Tipo de atividade</th>
                            <th style={{textAlign: "right", width: "10%"}}>Quem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders !== null && orders.map((order, index) => (
                            <>
                                <tr style={{height: "38px"}}>
                                    <td>{order.linhaName}</td>
                                    {/*<td>{order.equipmentName}</td>*/}
                                    <td>{order.task}</td>
                                    <td>{order.planned === null ?
                                        ConvertToReadableDateNormal(order.nextPlanned)
                                        : ConvertToReadableDateNormal(order.planned)}
                                    </td>
                                    <td style={{textAlign: "center"}}>{`${order.frequency}`}</td>
                                    <td style={{textAlign: "right"}}>{order.activityType}</td>
                                    <td style={{textAlign: "right"}}>{order.who}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </>
    );
}