const VerifyInput = (value, pattern) => {
    if (value === "") {
        return true;
    }
    if (pattern === "email") {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailPattern.test(value);
    }
    if (pattern === "password") {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return passwordPattern.test(value);
    }
    if (pattern === "name") {
        const namePattern = /^[a-zA-ZÀ-ÿ\s]{3,}$/;
        return namePattern.test(value);
    }
    if (pattern === "number") {
        const numberPattern = /^[0-9]+$/;
        return numberPattern.test(value);
    }
    if (pattern === "positiveNumber") {
        const positiveNumberPattern = /^(0|[1-9][0-9]*)$/;
        return positiveNumberPattern.test(value);
    }
    if (pattern === "phone") {
        const phonePattern = /^[0-9]{9}$/;
        return phonePattern.test(value);
    }
    if (pattern === "nif") {
        const nifPattern = /^[0-9]{9}$/;
        return nifPattern.test(value);
    }
    if (pattern === "niss") {
        const nissPattern = /^[0-9]{11}$/;
        return nissPattern.test(value);
    }
    if (pattern === "nib") {
        const nibPattern = /^[0-9]{21}$/;
        return nibPattern.test(value);
    }
    if (pattern === "iban") {
        const ibanPattern = /^[A-Z]{2}[0-9]{21}$/;
        return ibanPattern.test(value);
    }
    if (pattern === "swift") {
        const swiftPattern = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        return swiftPattern.test(value);
    }
    return false;
}

export default VerifyInput;