export const frequencyUnit = [
    "Dias",
    "Semanas",
    "Meses",
    "Anos"
];

export const activityType = [
    {Value: 0, Name: "Elétrica"},
    {Value: 1, Name: "Geral"},
    {Value: 2, Name: "Limpeza"},
    {Value: 3, Name: "Lubrificação"},
    {Value: 4, Name: "Mecânica"},
    {Value: 5, Name: "Pneumática"},
];

export const Whos = [
    {Value: 0, Name: "Externa"},
    {Value: 1, Name: "Operador"},
    {Value: 2, Name: "Técnico Manutenção"}
];

export const maintenanceType = [
    {Value: 0, Name: "Corretiva"},
    {Value: 1, Name: "Preventiva"},
    {Value: 2, Name: "Preditiva"},
    {Value: 3, Name: "Melhoria"}
]