import {useNavigate} from "react-router-dom";
import {Button, CssBaseline, CssVarsProvider, Snackbar, Typography} from "@mui/joy";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import {useAppContext} from "../../Contexts/AppContext";
import {useEffect} from "react";

const NotificationSnackbar = () => {
    const { notificationData, notificationOpen, setNotificationOpen } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (notificationOpen) {
            const timer = setTimeout(() => {
                setNotificationOpen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationOpen, setNotificationOpen]);

    return (
        <CssVarsProvider>
            <CssBaseline />
            <Snackbar
                open={notificationOpen}
                onClose={(event, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setNotificationOpen(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                startDecorator={<NotificationsActiveOutlinedIcon fontSize="xl" />}
                endDecorator={
                    <Button
                        size="sm"
                        variant="plain"
                        color="neutral"
                        onClick={() => {
                            navigate(notificationData.Url);
                        }}
                    >
                        <Typography level="h6" fontSize="13px">
                            {notificationData.buttonMessage}
                        </Typography>
                    </Button>
                }
            >
                <Typography level="title-xs">{notificationData.message}</Typography>
            </Snackbar>
        </CssVarsProvider>
    );
};

export default NotificationSnackbar;