import axios from "axios";

const refreshToken = async () => {
  const baseURL =
    process.env.REACT_APP_BASE_URL || "https://materiaisdev.ableconsulting.pt";

  try {
    const response = await axios.post(
      `${baseURL}/api/auth/refresh-token`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    const newToken = response.data;
    localStorage.setItem("jwt", newToken);

    return newToken;
  } catch (error) {
    console.error(error);
  }
};

export default refreshToken;
