export default function GetAmountFromFrequencyUnit(frequencyUnit) {
  switch (frequencyUnit) {
    case "Dias":
      return 30;
    case "Semanas":
      return 24;
    case "Meses":
      return 36;
    case "Anos":
      return 10;
    default:
      return 1;
  }
}