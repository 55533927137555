import { createContext, useContext, useState } from "react";

export const PageContext = createContext();

export const PageProvider = ({ children, page, type }) => {
    const [materialTypePage, setMaterialTypePage] = useState(page);

    return (
        <PageContext.Provider
            value={{
                materialTypePage,
                setMaterialTypePage,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = () => {
    return useContext(PageContext);
};
